import { ref, computed } from "@vue/composition-api";
import axiosIns from "@/libs/axios";

function requestLoadCompassionUsers(reqData) {
  return axiosIns.post("/compassion/admin/customers", reqData, {});
}
function loadCustomerQuestionnaire(reqData) {
  return axiosIns.post("/compassion/admin/questionnaire", reqData, {});
}
function loadCustomerDocumentTypes(reqData) {
  return axiosIns.post("/compassion/admin/documentTypes", reqData, {});
}
function loadCustomerScannedId(reqData) {
  return axiosIns.post("/compassion/admin/scannedId", reqData, {});
}
function loadCustomerAddress(reqData) {
  return axiosIns.post("/compassion/admin/address", reqData, {});
}
function loadCustomerContact(reqData) {
  return axiosIns.post("/compassion/admin/contact", reqData, {});
}
function loadCustomerReleases(reqData) {
  return axiosIns.post("/compassion/admin/releases", reqData, {});
}
function loadCustomerDispensaries(req) {
  return axiosIns.post("/compassion/admin/dispensaries", req, {});
}
function loadCustomerIdDocuments(reqData) {
  return axiosIns.post("/compassion/admin/idDocuments", reqData, {});
}
function loadCustomerDocumentImage(reqData) {
  return axiosIns.post("/compassion/admin/documentImage", reqData, {});
}
function confirmValidateCustomer(reqData) {
  return axiosIns.post("/compassion/admin/validatePatient", reqData, {});
}

export function lookupIntegrationPatient(req) {
  return axiosIns.post("/compassion/admin/partner/patientLookup", req, {});
}

export function fetchSavedPartnerData(req) {
  return axiosIns.post("/compassion/admin/partner/savedDispensaryMapping", req, {});
}

export function createDispensaryPatient(req) {
  return axiosIns.post("/compassion/admin/partner/createDispensaryPatient", req, {});
}

export function deletePatientDocument(req) {
  return axiosIns.post("/compassion/admin/deletePatientDocument", req, {});
}

export const usersList = ref([]);
export const filterBy = ref(null);
export const filterExpired = ref(false);
export const sortBy = ref("fullName");
export const sortOrder = ref("asc");
const perPage = ref(25);
const loading = ref(false);
let lastPageItem = 0;
let firstPageItem = 0;
const totalRows = ref(0);
export const apiResponse = ref("");
export const apiResponseType = ref("info");
export const showResponse = ref(false);

export const setPerPage = (val) => (perPage.value = val);
export const getPerPage = computed(() => perPage.value);
export const getLoading = computed(() => loading.value);
export const getTotalRows = computed(() => totalRows.value);
export const getUserList = computed(() => usersList.value);

export const setLoading = (req) => {
  loading.value = req;
};

export const validateEmailPhone = async (req) => {
  try {
    await requestValidateEmailPhone(req);
  } catch (err) {
    showResponse.value = true;
    err.response.config = {};
    err.response.request = {};
    console.log(err.response);
    const code = err.response ? err.response.status + " - " + err.response.statusText : "Unknown";
    apiResponse.value = "Phone/Email valiadation failed with : " + code + "(" + err.response.message ? err.response.message : "" + ")";
    apiResponseType.value = "danger";
  }
};

export const validateCustomer = async (req) => {
  try {
    await confirmValidateCustomer(req);
  } catch (err) {
    showResponse.value = true;
    err.response.config = {};
    err.response.request = {};
    console.log(err.response);
    const code = err.response ? err.response.status + " - " + err.response.statusText : "Unknown";
    apiResponse.value = "Validation failed with : " + code;
    apiResponseType.value = "danger";
  }
};

export const listIdDocuments = async (req) => {
  try {
    loading.value = true;
    console.log(req);
    const {
      data: { idDocuments }
    } = await loadCustomerIdDocuments(req);
    return idDocuments;
  } catch (err) {
    showResponse.value = true;
    err.response.config = {};
    err.response.request = {};
    console.log(err.response);
    const code = err.response ? err.response.status + " - " + err.response.statusText : "Unknown";
    apiResponse.value = "ID Document loading failed with : " + code;
    apiResponseType.value = "danger";
    return {};
  } finally {
    loading.value = false;
  }
};

export const loadDocumentImage = async (req) => {
  try {
    loading.value = true;
    console.log(req);
    const { data } = await loadCustomerDocumentImage(req);
    return data;
  } catch (err) {
    showResponse.value = true;
    err.response.config = {};
    err.response.request = {};
    console.log(err.response);
    const code = err.response ? err.response.status + " - " + err.response.statusText : "Unknown";
    apiResponse.value = "Image loading failed with : " + code;
    apiResponseType.value = "danger";
    return {};
  } finally {
    loading.value = false;
  }
};

export const loadInDispensaries = async (req) => {
  try {
    loading.value = true;
    console.log(req);
    const {
      data: { dispensaries }
    } = await loadCustomerDispensaries(req);
    return dispensaries;
  } catch (err) {
    showResponse.value = true;
    if (err.response) {
      err.response.config = {};
      err.response.request = {};
    }
    console.log(err);
    const code = err.response ? err.response.status + " - " + err.response.statusText : "Unknown";
    apiResponse.value = "Related dispensaries loading failed with : " + code;
    apiResponseType.value = "danger";
    return {};
  } finally {
    loading.value = false;
  }
};

export const loadReleases = async (req) => {
  try {
    loading.value = true;
    console.log(req);
    const {
      data: { releases }
    } = await loadCustomerReleases(req);
    return releases;
  } catch (err) {
    showResponse.value = true;
    err.response.config = {};
    err.response.request = {};
    console.log(err.response);
    const code = err.response ? err.response.status + " - " + err.response.statusText : "Unknown";
    apiResponse.value = "Releases loading failed with : " + code;
    apiResponseType.value = "danger";
    return {};
  } finally {
    loading.value = false;
  }
};

export const loadAddress = async (req) => {
  try {
    loading.value = true;
    console.log(req);
    const {
      data: { address }
    } = await loadCustomerAddress(req);
    return address;
  } catch (err) {
    showResponse.value = true;
    err.response.config = {};
    err.response.request = {};
    console.log(err.response);
    const code = err.response ? err.response.status + " - " + err.response.statusText : "Unknown";
    apiResponse.value = "Address loading failed with : " + code;
    apiResponseType.value = "danger";
    return {};
  } finally {
    loading.value = false;
  }
};

export const loadContact = async (req) => {
  try {
    loading.value = true;
    console.log(req);
    const {
      data: { contact }
    } = await loadCustomerContact(req);
    return contact;
  } catch (err) {
    showResponse.value = true;
    err.response.config = {};
    err.response.request = {};
    console.log(err.response);
    const code = err.response ? err.response.status + " - " + err.response.statusText : "Unknown";
    apiResponse.value = "Contact loading failed with : " + code;
    apiResponseType.value = "danger";
    return {};
  } finally {
    loading.value = false;
  }
};

export const loadScannedId = async (req) => {
  try {
    loading.value = true;
    console.log(req);
    const {
      data: { idScan }
    } = await loadCustomerScannedId(req);
    return idScan;
  } catch (err) {
    showResponse.value = true;
    err.response.config = {};
    err.response.request = {};
    console.log(err.response);
    const code = err.response ? err.response.status + " - " + err.response.statusText : "Unknown";
    apiResponse.value = "ID loading failed with : " + code;
    apiResponseType.value = "danger";
    return {};
  } finally {
    loading.value = false;
  }
};

export const loadDocumentTypes = async (req) => {
  try {
    loading.value = true;
    console.log(req);
    const {
      data: { documentTypes }
    } = await loadCustomerDocumentTypes(req);
    return documentTypes;
  } catch (err) {
    showResponse.value = true;
    err.response.config = {};
    err.response.request = {};
    console.log(err.response);
    const code = err.response ? err.response.status + " - " + err.response.statusText : "Unknown";
    apiResponse.value = "Uploaded Document loading failed with : " + code;
    apiResponseType.value = "danger";
    return {};
  } finally {
    loading.value = false;
  }
};

export const loadQualifications = async (req) => {
  try {
    loading.value = true;
    console.log(req);
    const {
      data: { questionnaire }
    } = await loadCustomerQuestionnaire(req);
    return questionnaire;
  } catch (err) {
    showResponse.value = true;
    err.response.config = {};
    err.response.request = {};
    console.log(err.response);
    const code = err.response ? err.response.status + " - " + err.response.statusText : "Unknown";
    apiResponse.value = "Qualification load failed with : " + code;
    apiResponseType.value = "danger";
    return {};
  } finally {
    loading.value = false;
  }
};

export const onLoadAllUsers = async ({ prevPage, nextPage, reset }) => {
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }
  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    sort: sortBy.value,
    sortOrder: sortOrder.value,
    filter: {
      search: filterBy.value || null,
      expiringInMonths: filterExpired.value ? 3 : null
    }
  };
  loading.value = true;
  try {
    const {
      data: { allUsers, pagination }
    } = await requestLoadCompassionUsers(reqData);
    if (allUsers) {
      usersList.value.splice(0, usersList.value.length);
      usersList.value.push(...allUsers);
    } else {
      usersList.value.splice(0, usersList.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
  } finally {
    loading.value = false;
  }
};
