<template>
  <div>
    <b-card :title="`Contact details for [${patient ? patient.fullName : ''}]`">
      <b-form-group
        id="input-group-name"
        label-cols="2"
        label-align="right"
        content-cols="6"
        invalid-feedback="Enter patient's full name here (required)"
        :state="!(!pcData.fullName || pcData.fullName.length === 0)"
      >
        <template #label>
          <b> Name: </b>
        </template>
        <b-form-input id="input-name" maxlength="250" v-model="pcData.fullName" type="search" required></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-email"
        label-cols="2"
        content-cols="6"
        label-align="right"
        invalid-feedback="Enter patient's email/phone if available (phone or email required)"
        :state="!((!pcData.email || pcData.email.length === 0) && (!pcData.phone || pcData.phone.length === 0))"
      >
        <template #label>
          <b>Contact: </b>
        </template>
        <b-form-row>
          <b-col role="group">
            <b-form-input id="input-email" maxlength="120" v-model="pcData.email" type="email" placeholder="Enter email address" required></b-form-input>
          </b-col>
          <b-col role="group">
            <b-form-input id="input-group-phone" maxlength="25" v-model="pcData.phone" placeholder="Enter phone number" required></b-form-input>
          </b-col>
        </b-form-row>
      </b-form-group>

      <b-form-group id="input-group-bday" label-cols="2" content-cols="6" label-align="right" invalid-feedback="Enter patient's birth date" :state="!!pcData.birthDate">
        <template #label>
          <b>Birthday: </b>
        </template>
        <b-form-row>
          <b-col role="group">
            <b-form-input id="input-bday" v-model="pcData.birthDate" type="date" required></b-form-input>
          </b-col>
        </b-form-row>
      </b-form-group>
      <b-card-footer>
        <b-button class="mt-1 ml-2 justify-content-end" variant="warning" @click="cancelSaveContact()"> Back </b-button>
        <b-button class="mt-1 ml-2 justify-content-end" variant="primary" @click="saveContact()"> Update </b-button>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { savePatientContact } from "./compassionPartnerRequests";
import { onMounted, ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";

export default {
  name: "PatientContact",
  components: {},
  props: {
    patient: Object,
    editContact: Object
  },
  directives: {
    Ripple
  },
  filters: {},
  setup(props, { emit, refs, parent }) {
    const pc = {
      phone: "",
      email: "",
      fullName: "",
      birthDate: "",
      patientUid: ""
    };

    const saving = ref(false);

    const pcData = ref(pc);

    const cancelSaveContact = () => {
      emit("hide-patient-contact");
    };

    const saveContact = async () => {
      try {
        saving.value = true;
        const req = pcData.value;

        await savePatientContact(req);
        emit("hide-patient-contact");
      } catch (err) {
        console.log("Error saving", err);
        parent.$bvToast.toast(`Error saving contact:  ${err.message}`, {
          title: "Error",
          autoHideDelay: 14000,
          variant: "danger",
          solid: true,
          appendToast: true
        });
      } finally {
        saving.value = false;
      }
    };
    onMounted(() => {
      //onsole.log("refs", refs);
      if (props.editContact) {
        pcData.value.phone = props.editContact.phone;
        pcData.value.email = props.editContact.email;
        pcData.value.fullName = props.editContact.fullName;
        pcData.value.birthDate = props.editContact.birthDate;
      }
      pcData.value.patientUid = props.patient.ccUid;
      console.log("mounted!", props.editContact, pcData.value, props.patient);
    });

    return {
      saveContact,
      cancelSaveContact,
      pcData,
      saving
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
