<template>
  <div>
    <b-card :title="`Documents for [${patient ? patient.fullName : ''}]`">
      <b-card-text style="margin-bottom: 50px !important">
        <b-table striped hover :items="allDocs" :fields="fields"> </b-table>
      </b-card-text>

      <b-card-footer>
        <b-button class="mt-1 ml-2 justify-content-end" variant="warning" @click="cancelSaveDocument()"> Back </b-button>
        <b-button class="mt-1 ml-2 justify-content-end" variant="primary" @click="saveDocument()"> Update </b-button>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { uploadDocument } from "./onboardingSteps";

export default {
  name: "PatientUploadDocs",
  components: {},
  props: {
    partnerIntegration: Object,
    patient: Object,
    documents: Array
  },
  directives: {
    Ripple
  },
  filters: {},
  setup(props, { emit, refs, parent }) {
    const fields = ["description", "selected"];

    //  @upload-selected-docs="uploadSelected(lookingUp)"
    //  @cancel-upload="() => uploadPatientDocs = false"

    const saving = ref(false);
    const allDocs = ref([]);

    const cancelSaveDocument = () => {
      console.log("going back");
      emit("hide-patient-document");
    };

    onMounted(() => {
      allDocs.value = props.documents;
      console.log("uploaddocs!", props.partnerIntegration, props.patient, props.documents);
    });

    return {
      saveDocument,
      cancelSaveDocument,
      saving,
      fields,
      allDocs
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
