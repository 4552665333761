<template>
  <div>
    <b-card
      :title="`Partner Patient Info for [${patient ? patient.fullName : ''}] on ${partnerIntegration ? partnerIntegration.dispensary : ''} (${
        partnerIntegration ? partnerIntegration.pos : ''
      })`"
      v-if="!viewDocs"
    >
      <b-table responsive :fields="fields" :items="partnerCustomers" show-empty>
        <template #cell(active)="data">
          Active:
          <template v-if="data.value"> <feather-icon icon="CheckCircleIcon" size="20" class="text-success mr-1" /> </template>
          <template v-else>
            <feather-icon icon="XCircleIcon" size="20" class="text-danger mr-1" />
          </template>
          <template v-if="data.item.mappedUid">
            <br />
            <b-badge variant="primary">Linked</b-badge>
          </template>
          <br />
          <template v-if="data.item.imageList && data.item.imageList.length > 0">
            <b-link href="#" @click="viewDocuments(data.item.imageList)"
              >View docs <b-badge variant="primary">{{ data.item.imageList.length }}</b-badge></b-link
            >
          </template>
        </template>

        <template #cell(addresses)="data">
          <ol>
            <li v-for="(id, i) of data.value" :key="i">{{ id.street1 }}, {{ id.city }} {{ id.primary ? "primary" : "" }}</li>
          </ol>
        </template>
        <template #cell(email)="data">
          {{ data.value }}
          <br />
          {{ data.item.phone }}
        </template>
        <template #cell(first_name)="data">
          {{ data.value }} {{ data.item.middle_name }} {{ data.item.last_name }} <span v-if="data.item.nickname">({{ data.item.nickname }})</span>
        </template>
        <template #cell(drivers_license)="data"> {{ data.drivers_license }} {{ data.item.drivers_license_expiration | formatDateYMD }} </template>
        <template #cell(state_medical_id)="data"> {{ data.state_medical_id }} {{ data.item.permit_expiration | formatDateYMD }} </template>
        <template #cell(actions)="data">
          <template v-if="!data.item.mappedUid">
            <b-button size="sm" variant="info" @click="mapToPatient(data.item)"> Link </b-button>
          </template>
          <template v-if="data.item.mappedUid">
            <b-button size="sm" variant="info" @click="refreshData(data.item)"> Refresh </b-button>
          </template>
        </template>
        <template #empty>
          No data in dispensary.
          <b-button size="sm" variant="info" @click="createPatient()" :disabled="partnerCustomers && partnerCustomers.length > 0"> Click to create </b-button>
        </template>
      </b-table>
      <b-card-footer>
        <b-button size="sm" variant="danger" @click="cancelPatientMap()"> Cancel </b-button>
      </b-card-footer>
    </b-card>
    <b-card title="Images" footer-tag="footer" v-if="viewDocs">
      <b-tabs content-class="mt-6">
        <b-tab no-body v-for="(id, i) of docsFor" :key="i" :title="id.type">
          <b-card-img no-body :src="id.url" :alt="id.type" fluid />
        </b-tab>
      </b-tabs>
      <template #footer>
        <b-button size="sm" variant="danger" @click="doneWithDocs()"> Close </b-button>
      </template>
    </b-card>

    <b-modal
      ref="modalConfirmRef"
      id="confirm-mapping-partner"
      centered
      header-bg-variant="info"
      title="Map to patient in dispensary"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
      scrollable
    >
      <div class="d-block">
        <h3>Confirm mapping Compassion patient</h3>
      </div>
      <div class="d-block">
        {{ patient.fullName }}
      </div>
      <br />
      <div class="d-block">
        <h3>To dispensary patient</h3>
      </div>

      <div class="d-block">
        {{ (mappingPatient && mappingPatient.first_name) || "" }} {{ (mappingPatient && mappingPatient.last_name) || "" }}
        <span v-if="mappingPatient && mappingPatient.nickname"> ({{ (mappingPatient && mappingPatient.nickname) || "" }}) </span>
        <br />
        {{ (mappingPatient && mappingPatient.email) || "" }}
        <br />
        {{ (mappingPatient && mappingPatient.phone) || "" }}
      </div>

      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="$bvModal.hide('confirm-mapping-partner')" :disabled="saving"> Cancel </b-button>
        <b-button size="sm" variant="outline-warning" @click="confirmMapToPatient()" :disabled="saving">
          Link
          <b-spinner v-if="saving" small style="margin-bottom: 3px" />
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapCustomer, refreshPartnerData } from "./compassionPartnerRequests";
import { onMounted, ref, reactive, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { formatDateYMD } from "@core/utils/filter";

export default {
  name: "PartnerCustomer",
  components: {},
  props: {
    partnerCustomers: Array,
    partnerIntegration: Object,
    patient: Object
  },
  directives: {
    Ripple
  },
  filters: { formatDateYMD },
  setup(props, { emit, refs, parent }) {
    const fields = [
      { key: "first_name", label: "Name" },
      { key: "birthday", label: "Birthday", formatter: formatDateYMD },
      { key: "email", label: "Email" },
      { key: "drivers_license", label: "License" },
      { key: "state_medical_id", label: "Medical ID" },
      { key: "addresses", label: "addresses" },
      "active",
      "actions"
    ];

    const viewDocs = ref(false);
    const docsFor = ref(null);
    const confirmMap = ref(false);
    const mappingPatient = ref(null);
    const saving = ref(false);

    const viewDocuments = (doclist) => {
      viewDocs.value = true;
      docsFor.value = doclist;
    };

    const cancelPatientMap = () => {
      emit("cancel-patient-mapping");
    };

    const createPatient = () => {
      emit("create-patient-dispensary");
    };

    const doneWithDocs = () => {
      viewDocs.value = false;
      docsFor.value = null;
    };

    const refreshData = async (pat) => {
      try {
        saving.value = true;
        const req = {
          customerId: pat.customer_id,
          partnerUid: props.partnerIntegration.partnerUid,
          patientUid: props.partnerIntegration.patientUid
        };
        const res = await refreshPartnerData(req);
        console.log("got refresh response", res);
        emit("cancel-patient-mapping");
      } catch (err) {
        console.log("Error refreshing", err);
        parent.$bvToast.toast(`Error refreshing data:  ${err.message}`, {
          title: "Error",
          autoHideDelay: 14000,
          variant: "danger",
          solid: true,
          appendToast: true
        });
      } finally {
        saving.value = false;
      }
    };

    const mapToPatient = async (pat) => {
      confirmMap.value = true;
      refs.modalConfirmRef.show("confirm-mapping-partner");
      mappingPatient.value = pat;
    };

    const confirmMapToPatient = async () => {
      confirmMap.value = false;
      try {
        saving.value = true;
        const req = {
          customerId: mappingPatient.value.customer_id,
          partnerUid: props.partnerIntegration.partnerUid,
          patientUid: props.partnerIntegration.patientUid
        };
        await mapCustomer(req);
        emit("cancel-patient-mapping");
      } catch (err) {
        console.log("Error saving", err);
        parent.$bvToast.toast(`Error saving mapping:  ${err.message}`, {
          title: "Error",
          autoHideDelay: 14000,
          variant: "danger",
          solid: true,
          appendToast: true
        });
      } finally {
        saving.value = false;
      }
    };

    onMounted(() => {
      //onsole.log("refs", refs);
      console.log("mounted!", props.partnerCustomers, props.partnerIntegration, props.patient);
    });

    return {
      fields,
      mapToPatient,
      viewDocs,
      viewDocuments,
      doneWithDocs,
      docsFor,
      cancelPatientMap,
      confirmMap,
      confirmMapToPatient,
      refreshData,
      mappingPatient,
      saving,
      createPatient
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
