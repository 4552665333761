<template>
  <div>
    <b-card :title="`Documents for [${patient ? patient.fullName : ''}]`">
      <b-card-text style="margin-bottom: 50px !important">
        <b-form inline>
          <b-form-group label-cols="3" label-align-sm="left" label-size="md" label-for="documentList" class="mb-0 search-bar-margin">
            <b-form-select id="documentList" v-model="selectedDocument" :options="documentOptions"> {{}}</b-form-select>
          </b-form-group>
          <b-button class="mt-1 ml-2 justify-content-end" variant="info" @click="addDocumentType()"> + </b-button>
        </b-form>
      </b-card-text>

      <b-card-text v-for="(doc, index) of addedDocuments" :key="doc.id" style="margin-right: 20%">
        <b-form-group :id="'input-group-' + index" :label-for="'input-group-file-' + index" label-cols="3" label-align="right">
          <template #label>
            <b>{{ doc.fileType }}</b>
          </template>
          <b-form-group
            :id="'input-group-doc-details-' + doc.id + '-' + index"
            invalid-feedback="Document information is required"
            :state="!((!doc.requiresNumber || doc.requiresNumber.length === 0) && (!doc.requiresExpiration || doc.requiresExpiration.length === 0))"
            v-if="doc.requiresNumber || doc.requiresExpiration"
          >
            <b-form-row v-if="doc.requiresNumber || doc.requiresExpiration || doc.requiresName">
              <b-col role="group">
                <b-form-input
                  :id="'input-number-' + doc.id + '-' + index"
                  maxlength="30"
                  v-model="doc.documentNumber"
                  type="search"
                  :placeholder="doc.numberText"
                  v-if="doc.requiresNumber"
                ></b-form-input>
              </b-col>
              <b-col role="group" v-if="doc.requiresName">
                <b-form-input
                  :id="'input-name-' + doc.id + '-' + index"
                  maxlength="30"
                  v-model="doc.physicianName"
                  type="search"
                  :placeholder="doc.nameText"
                  v-if="doc.requiresName"
                ></b-form-input>
              </b-col>
              <b-col role="group" v-if="doc.requiresExpiration">
                <b-form-datepicker
                  :id="'input-group-expiration-' + doc.id + '-' + index"
                  v-model="doc.documentExpiration"
                  v-if="doc.requiresExpiration"
                  :placeholder="doc.dateText"
                  :min="getMinExpiration"
                ></b-form-datepicker>
              </b-col>
              <b-col role="group" v-if="doc.requiresDocumentDate">
                <b-form-datepicker
                  :id="'input-group-document-date-' + doc.id + '-' + index"
                  v-model="doc.documentDate"
                  v-if="doc.requiresDocumentDate"
                  :placeholder="doc.dateText"
                  :max="getMaxDate"
                ></b-form-datepicker>
              </b-col>
            </b-form-row>
          </b-form-group>
          <b-form-file
            :id="'input-group-file-' + index"
            v-model="doc.file"
            accept="image/*, application/pdf"
            :state="Boolean(doc.file)"
            @input="fileSelectedChange(doc, $event)"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
          <small class="text-danger" :key="filechanged">{{ !doc.file ? doc.description + " is required" : "" }}</small>
        </b-form-group>
        <template v-if="doc.breakAfter">
          <br />
          <b-card-text />
        </template>
      </b-card-text>

      <b-card-footer>
        <b-button class="mt-1 ml-2 justify-content-end" variant="warning" @click="cancelSaveDocument()"> Back </b-button>
        <b-button class="mt-1 ml-2 justify-content-end" variant="primary" @click="saveDocument()"> Update </b-button>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { uploadDocument } from "./onboardingSteps";

export default {
  name: "PatientDocument",
  components: {},
  props: {
    type: String,
    patient: Object,
    documents: Array
  },
  directives: {
    Ripple
  },
  filters: {},
  setup(props, { emit, refs, parent }) {
    const docTypes = [
      {
        value: 1,
        id: 1,
        documentType: "ID_FRONT",
        type: "ID",
        description: "California State ID",
        text: "California State ID",
        fileType: "CA ID Front",
        bothSides: true,
        file: null,
        progress: false,
        success: false,
        failure: false,
        requiresExpiration: true,
        requiresNumber: true,
        numberText: "Enter License Number",
        dateText: "Enter Expiration Date"
      },
      {
        value: 5,
        id: 5,
        type: "Veteran Id",
        description: "Veteran ID",
        fileType: "Veteran ID Front",
        text: "Veteran ID",
        documentType: "VETID_FRONT",
        bothSides: true,
        file: null,
        progress: false,
        success: false,
        failure: false,
        requiresExpiration: true,
        requiresNumber: true,
        numberText: "Enter ID Number",
        dateText: "Enter Expiration Date"
      },

      {
        value: 6,
        id: 6,
        type: "Medical id card",
        description: "Medical ID Card",
        text: "Medical ID Card",
        fileType: "Medical ID",
        documentType: "MEDICAL_CARD",
        bothSides: false,
        file: null,
        progress: false,
        success: false,
        failure: false,
        requiresExpiration: true,
        requiresNumber: true,
        numberText: "Enter Medical ID Card Number",
        dateText: "Enter Medical Card Expiration Date"
      },
      {
        value: 2,
        id: 2,
        type: "Income",
        description: "Income Verification",
        text: "Income Verification",
        documentType: "INCOME",
        bothSides: false,
        file: null,
        progress: false,
        success: false,
        failure: false,
        notAdded: false,
        breakAfter: true
      },
      {
        id: 3,
        documentType: "DIAGNOSIS_MAIN",
        text: "Medical Diagnosis",
        type: "Diagnosis",
        description: "Medical Diagnosis",
        bothSides: false,
        file: null,
        progress: false,
        success: false,
        failure: false
      },
      {
        value: 4,
        id: 4,
        type: "Recommendation",
        description: "Doctors Recommendation",
        text: "Doctors Recommendation",
        documentType: "RECOMMENDATION_MAIN",
        bothSides: false,
        file: null,
        progress: false,
        success: false,
        failure: false,
        notAdded: false,
        requiresNumber: true,
        requiresExpiration: false,
        requiresDocumentDate: true,
        requiresName: true,
        numberText: "Enter Physicians License Number",
        nameText: "Enter Physician's Name",
        dateText: "Enter Document Date"
      }
    ];

    const idb = {
      type: "ID",
      documentType: "ID_BACK",
      id: 10,
      description: "California State ID BACK",
      fileType: "CA ID Back",
      requiresExpiration: false,
      requiresNumber: false,
      breakAfter: true,
      file: null,
      progress: false,
      success: false,
      failure: false
    };

    const vidb = {
      documentType: "VETID_BACK",
      id: 11,
      description: "Veteran ID BACK",
      fileType: "Veteran ID Back",
      requiresExpiration: false,
      requiresNumber: false,
      breakAfter: true,
      file: null,
      progress: false,
      success: false,
      failure: false
    };

    const saving = ref(false);
    const filechanged = ref(10);

    const selectedDocument = ref(null);
    const documentOptions = ref(docTypes);
    const addedDocuments = ref([]);

    const addDocumentType = () => {
      if (!selectedDocument.value) {
        return;
      }
      const fdoc = docTypes.find((d) => d.value === selectedDocument.value);
      if (fdoc) {
        if (fdoc.value === 1) {
          addedDocuments.value.unshift(idb);
        }
        if (fdoc.value === 5) {
          addedDocuments.value.unshift(vidb);
        }
        addedDocuments.value.unshift(fdoc);
      }
    };

    const getMinExpiration = computed(() => {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      const minDate = new Date(today);
      return minDate;
    });

    const fileSelectedChange = async (doc, evt) => {
      console.log("doc", doc);
      console.log('evt", evt');
      filechanged.value++;
    };
    const cancelSaveDocument = () => {
      console.log("going back");
      emit("hide-patient-document");
    };

    const getDocumentForUpload = async (docu) => {
      let formData = new FormData();
      if (docu.file) {
        formData.append("document", docu.file);
        formData.append("originalFileName", docu.file.name);
      }
      formData.append("documentType", docu.documentType);
      if (docu.documentNumber) {
        formData.append("documentNumber", docu.documentNumber);
      }
      if (docu.requiresExpiration && docu.documentExpiration) {
        formData.append("expiration", docu.documentExpiration);
      }
      if (docu.requiresDocumentDate && docu.documentDate) {
        formData.append("documentDate", docu.documentDate);
      }
      if (docu.physicianName) {
        formData.append("physicianName", docu.physicianName);
      }
      formData.append("patientUid", props.patient.ccUid);
      console.log("File upload info", formData);
      return formData;
    };

    const saveDocument = async () => {
      try {
        saving.value = true;

        for (const d of addedDocuments.value) {
          d.progress = true;
          let ud = await getDocumentForUpload(d);

          const dresponse = await uploadDocument(ud);
          const data = dresponse.data;
          console.log("uploaded required document", data);
          d.progress = false;
          d.success = true;
          filechanged.value++;
        }

        emit("hide-patient-document");
      } catch (err) {
        console.log("Error saving", err);
        parent.$bvToast.toast(`Error saving document:  ${err.message}`, {
          title: "Error",
          autoHideDelay: 14000,
          variant: "danger",
          solid: true,
          appendToast: true
        });
      } finally {
        saving.value = false;
      }
    };
    onMounted(() => {
      //onsole.log("refs", refs);
      if (props.type === "ID") {
        documentOptions.value = [];
        documentOptions.value.push(docTypes.find((d) => d.value === 6));
        documentOptions.value.unshift(docTypes.find((d) => d.value === 5));
        documentOptions.value.unshift(docTypes.find((d) => d.value === 1));
      }
      console.log("mounted!", props.type, props.patient, props.documents);
    });

    return {
      saveDocument,
      cancelSaveDocument,
      saving,
      selectedDocument,
      documentOptions,
      addedDocuments,
      addDocumentType,
      filechanged,
      fileSelectedChange,
      getMinExpiration
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
