<template>
  <div>
    <b-card :title="`Contact details for [${patient ? patient.fullName : ''}]`">
      <b-card-text>
        <b-form-group id="input-group-1" label-cols="2" label-align="right">
          <template #label>
            <b>Street:</b>
          </template>
          <validation-provider #default="{ errors }" name="Street Address" rules="required" mode="eager">
            <b-form-input id="input-streetAddress" maxlength="200" v-model="address.street" required></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-card-text>
      <b-card-text>
        <b-form-group id="input-group-2" label-cols="2" label-align="right">
          <template #label>
            <b>City:</b>
          </template>
          <validation-provider #default="{ errors }" name="City" rules="required" mode="eager">
            <b-form-input id="input-city" maxlength="100" v-model="address.city" required></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-card-text>
      <b-card-text>
        <b-form-group id="input-group-3" label-cols="2" label-align="right">
          <template #label>
            <b>State:</b>
          </template>
          <validation-provider #default="{ errors }" name="State" rules="required" mode="eager">
            <b-form-input id="input-state" maxlength="2" v-model="address.state" required></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-card-text>
      <b-card-text>
        <b-form-group id="input-group-4" label-cols="2" label-align="right">
          <template #label>
            <b>Zip:</b>
          </template>
          <validation-provider #default="{ errors }" name="Zip" rules="required" mode="eager">
            <b-form-input id="input-zip" maxlength="10" v-model="address.zip" required></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-card-text>
      <b-card-text>
        <b-form-group id="input-group-5" label-cols="4" label-align="right">
          <template #label>
            <b>Reliable for delivery?</b>
          </template>

          <b-form-checkbox switch v-model="address.isReliable" />
        </b-form-group>
      </b-card-text>
      <b-card-footer>
        <b-button class="mt-1 ml-2 justify-content-end" variant="warning" @click="cancelSaveAddress()"> Back </b-button>
        <b-button class="mt-1 ml-2 justify-content-end" variant="primary" @click="saveAddress()"> Update </b-button>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { savePatientAddress } from "./compassionPartnerRequests";
import { onMounted, ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, email } from "@validations";

export default {
  name: "PatientAddress",
  components: { ValidationProvider, ValidationObserver },
  props: {
    patient: Object,
    editAddress: Object
  },
  directives: {
    Ripple
  },
  filters: {},
  setup(props, { emit, refs, parent }) {
    const addr = {
      street: "",
      city: "",
      state: "",
      zip: "",
      isReliable: false,
      patientUid: ""
    };

    const saving = ref(false);

    const address = ref(addr);

    const cancelSaveAddress = () => {
      emit("hide-patient-address");
    };

    const saveAddress = async () => {
      try {
        saving.value = true;
        const req = address.value;

        await savePatientAddress(req);
        emit("hide-patient-address");
      } catch (err) {
        console.log("Error saving", err);
        parent.$bvToast.toast(`Error saving address:  ${err.message}`, {
          title: "Error",
          autoHideDelay: 14000,
          variant: "danger",
          solid: true,
          appendToast: true
        });
      } finally {
        saving.value = false;
      }
    };
    onMounted(() => {
      //onsole.log("refs", refs);
      if (props.editAddress) {
        address.value.street = props.editAddress.street;
        address.value.city = props.editAddress.city;
        address.value.state = props.editAddress.state;
        address.value.zip = props.editAddress.zip;
        address.value.isReliable = props.editAddress.isReliable;
      }
      address.value.patientUid = props.patient.ccUid;
      console.log("mounted!", props.editAddress, address.value, props.patient);
    });

    return {
      saveAddress,
      cancelSaveAddress,
      address,
      saving,
      required,
      email
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
