<template>
  <div>
    <template v-if="props && props.bareCustomer && !uploadPatientDocs && !showLookupPatients && !addEditContact && !addEditAddress && !addNewId && !addMoreDocs && !largeImage">
      <b-card>
        <b-row class="match-height">
          <b-col lg="11">
            <h3>Patient: {{ props && props.bareCustomer ? props.bareCustomer.fullName : "Name not set" }}</h3>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col lg="1" />
          <b-col lg="9">
            <div>
              <b-card no-body>
                <b-tabs active-nav-item-class="font-weight-bold text-info" card v-model="tabIndex">
                  <b-tab title="Contact Info" key="contacts" @click="fetchContact">
                    <b-card-title> Contact </b-card-title>
                    <template v-if="props && props.bareCustomer && props.bareCustomer.hasContacts">
                      <b-card-text>
                        <b-col lg="7">
                          <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
                            <b-skeleton-wrapper :loading="getLoading">
                              <template #loading>
                                <b-card>
                                  <b-skeleton width="85%"></b-skeleton>
                                  <b-skeleton width="55%"></b-skeleton>
                                  <b-skeleton width="70%"></b-skeleton>
                                </b-card>
                              </template>
                              <b-table responsive :fields="fields" :items="contact" />
                            </b-skeleton-wrapper>
                          </b-overlay>
                        </b-col>
                      </b-card-text>
                      <b-card-text>
                        <b-link href="#" @click="addContactDetails()"> Update contact </b-link>
                      </b-card-text>
                    </template>
                    <template v-else>
                      <b-card-text> Contact information has not been set. <b-link href="#" @click="addContactDetails()"> Add contact info </b-link> </b-card-text>
                    </template>
                  </b-tab>
                  <b-tab title="Address" key="address" @click="fetchAddress">
                    <b-card-title> Address </b-card-title>
                    <template v-if="props && props.bareCustomer && props.bareCustomer.hasAddress">
                      <b-card-text>
                        <b-col lg="7">
                          <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
                            <b-skeleton-wrapper :loading="getLoading">
                              <template #loading>
                                <b-card>
                                  <b-skeleton width="85%"></b-skeleton>
                                  <b-skeleton width="55%"></b-skeleton>
                                  <b-skeleton width="70%"></b-skeleton>
                                </b-card>
                              </template>
                              <b-table responsive :fields="fields" :items="address" />
                            </b-skeleton-wrapper>
                          </b-overlay>
                        </b-col>
                        <br />
                      </b-card-text>
                      <b-card-text>
                        <b-link href="#" @click="addContactAddress()"> Update address </b-link>
                      </b-card-text>
                    </template>
                    <template v-else>
                      <b-card-text> Address has not been set. <b-link href="#" @click="addContactAddress()"> Add primary address </b-link> </b-card-text>
                    </template>
                  </b-tab>
                  <b-tab title="Qualification" key="quals" @click="fetchQualifications">
                    <b-card-title> Patient Qualification </b-card-title>
                    <template v-if="props && props.bareCustomer && props.bareCustomer.hasQuestions">
                      <b-card-text>
                        <b-col lg="7">
                          <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
                            <b-skeleton-wrapper :loading="getLoading">
                              <template #loading>
                                <b-card>
                                  <b-skeleton width="85%"></b-skeleton>
                                  <b-skeleton width="55%"></b-skeleton>
                                  <b-skeleton width="70%"></b-skeleton>
                                </b-card>
                              </template>
                              <b-table responsive :fields="fields" :items="qualifications">
                                <template #cell(selected)="data">
                                  <template v-if="data.value">
                                    <feather-icon icon="CheckCircleIcon" size="20" class="text-success mr-1" />
                                  </template>
                                  <template v-else>
                                    <feather-icon icon="XCircleIcon" size="20" class="text-danger mr-1" />
                                  </template>
                                </template>
                              </b-table>
                            </b-skeleton-wrapper>
                          </b-overlay>
                        </b-col>
                      </b-card-text>
                    </template>
                    <template v-else> Qualification responses have not been set. </template>
                  </b-tab>
                  <b-tab title="ID Details" key="ids" @click="fetchIdScan">
                    <b-card-title> ID Info </b-card-title>
                    <template v-if="props && props.bareCustomer && (props.bareCustomer.hasScannedId || (idDocuments && idDocuments.length > 0))">
                      <b-card-text>
                        <b-col lg="7">
                          <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
                            <b-skeleton-wrapper :loading="getLoading">
                              <template #loading>
                                <b-card>
                                  <b-skeleton width="85%"></b-skeleton>
                                  <b-skeleton width="55%"></b-skeleton>
                                  <b-skeleton width="70%"></b-skeleton>
                                </b-card>
                              </template>
                              <b-table responsive :fields="fields" :items="idScan" v-if="props.bareCustomer.hasScannedId" />
                            </b-skeleton-wrapper>
                          </b-overlay>
                        </b-col>

                        <b-row>
                          <b-col v-for="(docItem, index) of idDocuments" lg="6" :key="docItem.id">
                            <div class="accordion" role="tablist">
                              <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                  <b-button
                                    block
                                    v-b-toggle="'accordion-' + index"
                                    variant="info"
                                    v-b-tooltip.hover
                                    :title="`filename: ${docItem.name}, uploaded by: ${docItem.uploadedBy}`"
                                    >{{ docItem.type }}</b-button
                                  >
                                </b-card-header>
                                <b-collapse :id="'accordion-' + index" :accordion="'my-accordion-' + index" role="tabpanel" @shown="getDocument(docItem)">
                                  <b-card-body>
                                    <b-overlay :show="getLoading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                                      <b-skeleton-wrapper :loading="getLoading">
                                        <template #loading>
                                          <b-card>
                                            <b-skeleton width="85%"></b-skeleton>
                                            <b-skeleton width="55%"></b-skeleton>
                                            <b-skeleton width="70%"></b-skeleton>
                                          </b-card>
                                        </template>
                                        <div class="col-md-10">
                                          <a @click="setLargeImage(docItem.docImage, docItem)">
                                            <b-card-img bottom v-bind:src="docItem.docImage" :alt="docItem.name" :key="updated"></b-card-img>
                                            <h1
                                              v-if="docItem.deleted"
                                              class="text-danger font-weight-bolder"
                                              style="position: absolute; top: 50%; left: 50%; font-size: 4rem; transform: translate(-50%, -5%)"
                                            >
                                              DELETED
                                            </h1>
                                          </a>
                                        </div>
                                      </b-skeleton-wrapper>
                                      <b-button
                                        class="mt-2"
                                        :ref="'view-large-' + index"
                                        variant="outline-info"
                                        size="sm"
                                        aria-describedby="view-large-image"
                                        @click="setLargeImage(docItem.docImage, docItem)"
                                      >
                                        Larger
                                      </b-button>
                                      <b-button class="mt-2 ml-2" size="sm" variant="outline-warning" @click="disableDocument(docItem)" :disabled="docItem.deleted"
                                        >Delete</b-button
                                      >
                                    </b-overlay>
                                  </b-card-body>
                                </b-collapse>
                              </b-card>
                            </div>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </template>
                    <template v-else> ID has not been uploaded. <b-link href="#" @click="addPatientId()"> Add ID </b-link> </template>
                  </b-tab>
                  <b-tab title="Releases" key="release" @click="fetchReleases">
                    <b-card-title> Releases </b-card-title>
                    <template v-if="props && props.bareCustomer && props.bareCustomer.hasReleases">
                      <b-card-text>
                        <b-col lg="7">
                          <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
                            <b-skeleton-wrapper :loading="getLoading">
                              <template #loading>
                                <b-card>
                                  <b-skeleton width="85%"></b-skeleton>
                                  <b-skeleton width="55%"></b-skeleton>
                                  <b-skeleton width="70%"></b-skeleton>
                                </b-card>
                              </template>
                              <b-table responsive :fields="fields" :items="releases">
                                <template #cell(selected)="data">
                                  <template v-if="data.item.boolean">
                                    <template v-if="data.value">
                                      <feather-icon icon="CheckCircleIcon" size="20" class="text-success mr-1" />
                                    </template>
                                    <template v-else>
                                      <feather-icon icon="XCircleIcon" size="20" class="text-danger mr-1" />
                                    </template>
                                  </template>
                                  <template v-else>
                                    {{ data.value }}
                                  </template>
                                </template>
                              </b-table>
                            </b-skeleton-wrapper>
                          </b-overlay>
                        </b-col>
                      </b-card-text>
                    </template>
                    <template v-else> {{ props.bareCustomer.hasReleases }} Customer Releases have not been signed. </template>
                  </b-tab>
                  <b-tab title="All Documents" key="documents" @click="fetchDocumentTypes">
                    <b-card-title> All Documents (click to view)</b-card-title>
                    <template v-if="props && props.bareCustomer && Number(props.bareCustomer.documentCount || 0) > 0">
                      <b-row>
                        <b-col v-for="(docItem, index) of documentTypes" lg="6" :key="docItem.id">
                          <div class="accordion" role="tablist">
                            <b-card no-body class="mb-1">
                              <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'accordion-' + index" variant="info" v-b-tooltip.hover.html="docHtml(docItem)"
                                  >{{ docItem.type }} <span v-if="docItem.expiration">(EXP: {{ docItem.expiration }})</span>
                                  <span v-if="docItem.documentDate">(DT: {{ docItem.documentDate }})</span></b-button
                                >
                              </b-card-header>

                              <b-collapse :id="'accordion-' + index" :accordion="'my-accordion-' + index" role="tabpanel" @shown="getDocument(docItem)">
                                <b-card-body>
                                  <b-overlay :show="getLoading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                                    <b-skeleton-wrapper :loading="getLoading">
                                      <template #loading>
                                        <b-card>
                                          <b-skeleton width="85%"></b-skeleton>
                                          <b-skeleton width="55%"></b-skeleton>
                                          <b-skeleton width="70%"></b-skeleton>
                                        </b-card>
                                      </template>
                                      <!-- <b-card-img bottom class="img-thumbnail" v-bind:src="docItem.docImage" :alt="docItem.name" :key="updated"></b-card-img> -->

                                      <div class="col-md-10">
                                        <a @click="setLargeImage(docItem.docImage, docItem)">
                                          <b-card-img v-bind:src="docItem.docImage" :alt="docItem.name" :key="updated" />
                                          <h1
                                            v-if="docItem.deleted"
                                            class="text-danger font-weight-bolder"
                                            style="position: absolute; top: 50%; left: 50%; font-size: 4rem; transform: translate(-50%, -5%)"
                                          >
                                            DELETED
                                          </h1>
                                        </a>
                                      </div>
                                    </b-skeleton-wrapper>

                                    <b-button
                                      class="mt-2"
                                      :ref="'view-large-' + index"
                                      variant="outline-info"
                                      size="sm"
                                      aria-describedby="view-large-image"
                                      @click="setLargeImage(docItem.docImage, docItem)"
                                    >
                                      Larger
                                    </b-button>
                                    <b-button class="mt-2 ml-2" size="sm" variant="outline-warning" @click="disableDocument(docItem)" :disabled="docItem.deleted">Delete</b-button>
                                  </b-overlay>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-link href="#" @click="addPatientDocs()"> Update documents </b-link>
                      </b-row>
                    </template>
                    <template v-else> No documents have been uploaded. <b-link href="#" @click="addPatientDocs()"> Add patient documents </b-link> </template>
                  </b-tab>
                  <b-tab title="In Dispensaries" key="dispensaries" @click="fetchInDispensaries">
                    <b-card-title> Dispensaries </b-card-title>
                    <template v-if="dispensaries && dispensaries.length > 0">
                      <b-card-text>
                        <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
                          <b-skeleton-wrapper :loading="getLoading">
                            <template #loading>
                              <b-card>
                                <b-skeleton width="85%"></b-skeleton>
                                <b-skeleton width="55%"></b-skeleton>
                                <b-skeleton width="70%"></b-skeleton>
                              </b-card>
                            </template>
                            <b-table responsive :fields="dispensaryFields" :items="dispensaries">
                              <template #cell(externalId)="data">
                                <template v-if="data.value">
                                  <feather-icon
                                    v-b-tooltip.hover
                                    title="View patient documents from dispensary"
                                    class="text-success mr-1"
                                    icon="EyeIcon"
                                    size="27"
                                    @click="viewDispensaryDocs(data)"
                                  />
                                  <feather-icon
                                    v-b-tooltip.hover
                                    title="Upload patient documents to dispensary"
                                    icon="UploadCloudIcon"
                                    class="text-danger mr-1"
                                    size="27"
                                    @click="uploadDocsTo(data)"
                                  />
                                </template>
                                <template v-else>
                                  <span class="text-danger" v-if="!data.item.partnerUid">No dispensary for integration</span>
                                  <template v-if="data.item.partnerUid">
                                    <feather-icon
                                      v-b-tooltip.hover
                                      title="Lookup patient from dispensary"
                                      icon="SearchIcon"
                                      class="text-info mr-1"
                                      size="27"
                                      @click="lookupPatient(data)"
                                    />
                                  </template>
                                </template>
                              </template>
                              <template #cell(patientName)="data">
                                <template v-if="data.item.partnerUid">
                                  {{ data.value }}<br />
                                  {{ data.item.patientEmail }}<br />
                                  {{ data.item.patientPhone }}<br />
                                  {{ data.item.patientCity }} {{ data.item.patientZip }}
                                </template>
                              </template>
                            </b-table>
                          </b-skeleton-wrapper>
                        </b-overlay>
                      </b-card-text>
                    </template>
                    <template v-else> Customer not in any dispensaries. </template>
                  </b-tab>
                  <b-tab title="Notes" key="notes" v-if="props.bareCustomer.notes">
                    <b-card-title> Notes </b-card-title>
                    <div style="white-space: pre-wrap">
                      {{ props.bareCustomer.notes }}
                    </div>
                  </b-tab>
                </b-tabs>
              </b-card>
            </div>
          </b-col>
        </b-row>
        <b-row class="fs-1 mb-2 mt-2" height="100px;">
          <b-col md="1" />
          <b-col md="8 fs-1" height="100px;">
            <b-alert style="font-size: 2rem !important; padding-left: 15px !important" :variant="apiResponseType" :show="showResponse" dismissible>
              {{ apiResponse }}
            </b-alert>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <div>
            <span>
              <b-button class="mt-1 ml-2 justify-content-end" variant="warning" @click="onClose"> Back </b-button>
            </span>
            <span>
              <b-button class="mt-1 ml-2 justify-content-end" variant="primary" @click="confirmValidatePatient()" :disabled="patientValidated" v-if="!isOperator">
                Validate
              </b-button>
            </span>
          </div>
        </b-row>
      </b-card>
    </template>
    <template v-if="addEditAddress">
      <PatientAddress :patient="props.bareCustomer" @hide-patient-address="addEditAddress = false" :editAddress="rawAddress" />
    </template>
    <template v-if="addEditContact">
      <PatientContact :patient="props.bareCustomer" @hide-patient-contact="addEditContact = false" :editContact="rawContact" />
    </template>
    <template v-if="addNewId">
      <PatientDocument type="ID" :patient="props.bareCustomer" @hide-patient-document="addNewId = false" :documents="rawIdDocuments" />
    </template>
    <template v-if="addMoreDocs">
      <PatientDocument type="All" :patient="props.bareCustomer" @hide-patient-document="addMoreDocs = false" :documents="rawIdDocuments" />
    </template>
    <template v-if="addEditDocument">
      <PatientDocument :patient="props.bareCustomer" @hide-patient-document="addEditDocument = false" :documents="allRawDocuments" />
    </template>
    <template v-if="showLookupPatients">
      <b-alert variant="danger" :show="lookupError">Patient lookup unsuccessfull : {{ lookupError }}</b-alert>

      <PartnerCustomer
        @create-patient-dispensary="createPatient(lookingUp)"
        @cancel-patient-mapping="cancelPatientMap()"
        :partnerCustomers="lookedUpPatients"
        :partnerIntegration="lookingUp"
        :patient="props.bareCustomer"
      />
    </template>
    <template v-if="uploadPatientDocs">
      <PatientUploadDocs
        @upload-selected-docs="uploadSelected(lookingUp)"
        @cancel-upload="() => (uploadPatientDocs = false)"
        :documents="allRawDocuments"
        :partnerIntegration="uploadToPartner"
        :patient="props.bareCustomer"
      />
    </template>
    <template v-if="largeImage">
      <b-card class="overflow-hidden" style="max-width: 75%">
        <b-card-body :title="`Image for ${largeImageDoc.name}`">
          <b-card-text>
            <b-button ref="cancel" variant="outline-danger" size="sm" aria-describedby="cancel-label" @click="largeImage = false" style="float: right"> Close </b-button>
          </b-card-text>
          <b-card-img v-bind:src="largeImageView" class="rounded-0" img-bottom></b-card-img>
        </b-card-body>
      </b-card>
    </template>

    <b-modal id="confirmValidate" centered header-bg-variant="warning" busy v-model="showConfirmation" title="Validate Patient?" hide-header-close>
      <p>Confirm that the patient {{ props && props.bareCustomer ? props.bareCustomer.username : "" }} documents have been validated?</p>
      <br />
      <span v-if="validationProgress" class="text-info">Updating : <b-spinner v-if="validationProgress" variant="primary" small /></span>
      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="cancelView()"> Cancel </b-button>
        <b-button size="sm" variant="outline-warning" @click="validatePatient()"> Validate </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  getLoading,
  setLoading,
  loadQualifications,
  loadDocumentTypes,
  loadScannedId,
  loadReleases,
  loadInDispensaries,
  createDispensaryPatient,
  loadAddress,
  loadContact,
  listIdDocuments,
  loadDocumentImage,
  validateCustomer,
  showResponse,
  apiResponse,
  apiResponseType,
  lookupIntegrationPatient,
  fetchSavedPartnerData,
  deletePatientDocument
} from "./compassionUserRequests";
import { VBToggle } from "bootstrap-vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import { useRouter } from "@core/utils/utils";
import PartnerCustomer from "./PartnerCustomer.vue";
import PatientContact from "./PatientContact.vue";
import PatientAddress from "./PatientAddress.vue";
import PatientDocument from "./PatientDocument.vue";
import PatientUploadDocs from "./PatientUploadDocs.vue";
export default {
  name: "CustomerDetails",
  components: { PatientDocument, PartnerCustomer, PatientContact, PatientAddress, PatientUploadDocs },
  props: {
    bareCustomer: null,
    auditReason: null,
    isOperator: false
  },
  directives: {
    "b-toggle": VBToggle
  },
  setup(props, { root }) {
    const { router } = useRouter();
    const onClose = () => {
      router.back();
    };
    const customer = ref(null);
    const qualifications = ref([]);
    const documentTypes = ref([]);
    const idScan = ref([]);
    const releases = ref([]);
    const address = ref([]);
    const contact = ref([]);
    const dispensaries = ref([]);
    const idDocuments = ref([]);
    const updated = ref(0);
    const largeImage = ref(false);
    const largeImageView = ref("");
    const patientValidated = ref(false);
    const showConfirmation = ref(false);
    const validationProgress = ref(false);
    const showLookupPatients = ref(false);
    const lookedUpPatients = ref([]);
    const lookupError = ref(null);
    const lookingUp = ref(null);
    const tabIndex = ref(0);

    const confirmValidatePatient = () => {
      showConfirmation.value = true;
    };

    const cancelPatientMap = () => {
      showLookupPatients.value = false;
      lookingUp.value = null;
    };

    const cancelView = () => {
      showConfirmation.value = false;
    };
    const validatePatient = async () => {
      validationProgress.value = true;
      await validateCustomer({ customerUid: props.bareCustomer.ccUid, auditReason: props.auditReason });
      validationProgress.value = false;
      showConfirmation.value = false;
      updated.value = updated.value + 1;
      if (!showResponse.value) {
        router.back();
      }
    };
    const largeImageDoc = ref(null);
    const setLargeImage = (img, vieingDoc) => {
      largeImageView.value = img;
      largeImageDoc.value = vieingDoc;
      updated.value = updated.value + 1;
      largeImage.value = true;
    };

    const disableDocument = async (doc) => {
      console.log("disabling document", doc);
      try {
        const ret = await root.$bvModal.msgBoxConfirm("Please confirm that you want to delete this document.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        });
        if (!ret) {
          return;
        }
      } catch (err) {
        console.log(err);
      }

      try {
        setLoading(true);
        doc.deleted = true;
        const req = { documentUid: doc.documentUid, auditReason: props.auditReason };
        await deletePatientDocument(req);
      } catch (err) {
        root.$bvToast.toast(`Error deleting document:  ${err.message}`, {
          title: "Error",
          autoHideDelay: 14000,
          variant: "danger",
          solid: true,
          appendToast: true
        });
      } finally {
        setLoading(false);
      }
    };

    const getDocument = async (docItem) => {
      const data = await loadDocumentImage({ documentUid: docItem.documentUid, auditReason: props.auditReason });
      docItem.docImage = data;
      updated.value = updated.value + 1;
    };

    const fetchInDispensaries = async () => {
      const ans = await loadInDispensaries({ customerUid: props.bareCustomer.ccUid });
      dispensaries.value = ans;
      updated.value = updated.value + 1;
    };

    const fetchReleases = async () => {
      const ans = await loadReleases({ customerUid: props.bareCustomer.ccUid, auditReason: props.auditReason });
      const keys = Object.keys(ans);
      let tmp = [];
      for (const k of keys) {
        let d = {};
        switch (k) {
          case "mediaReleaseVersion":
            d.description = "Media Release Version";
            break;
          case "patientAcknowledgementVersion":
            d.description = "Patient Acknowledgement Version";
            break;
          case "medicalReleaseVersion":
            d.description = "Medical Release Version";
            break;
          case "mediaReleaseSocialMedia":
            d.boolean = true;
            d.description = "Social Media Release Signed";
            break;
          case "mediaReleasePublication":
            d.boolean = true;
            d.description = "Publication Release Signed";
            break;
          case "mediaReleaseNews":
            d.boolean = true;
            d.description = "News Release Signed";
            break;
          case "mediaReleaseMagazine":
            d.boolean = true;
            d.description = "Magazine Release Signed";
            break;
          case "mediaReleaseWebsite":
            d.boolean = true;
            d.description = "Website Release Signed";
            break;
          case "mediaReleasePromo":
            d.boolean = true;
            d.description = "Promo Media Release Signed";
            break;
          case "patientAcknowledgement":
            d.boolean = true;
            d.description = "Patient Acknowledgement Signed";
            break;
          case "medicalRelease":
            d.boolean = true;
            d.description = "Medical Release Signed";
            break;
          case "patientOptedOut":
            d.boolean = true;
            d.description = "Patient Opt Out";
            break;
          default:
            d.description = k;
        }
        d.selected = ans[k];
        tmp.push(d);
      }
      releases.value = tmp;
      console.log("releases is", releases.value);
      updated.value = updated.value + 1;
    };

    const addEditAddress = ref(false);
    const rawAddress = ref(null);
    const fetchAddress = async () => {
      const ans = await loadAddress({ customerUid: props.bareCustomer.ccUid, auditReason: props.auditReason });
      if (!ans) {
        return;
      }
      rawAddress.value = ans;
      const keys = Object.keys(ans);
      let tmp = [];
      for (const k of keys) {
        if (k === "patientUid" || k === "asString") {
          continue;
        }
        let d = {};
        switch (k) {
          case "isReliable":
            d.description = "Can Deliver Here?";
            break;
          case "city":
            d.description = "City";
            break;
          case "state":
            d.description = "State";
            break;
          case "zip":
            d.description = "Zip Code";
            break;
          case "street":
            d.description = "Street Address";
            break;
          default:
            d.description = k;
        }
        d.selected = ans[k];
        tmp.push(d);
      }
      address.value = tmp;
      console.log("address is", address.value);
      updated.value = updated.value + 1;
    };
    const addContactAddress = async () => {
      addEditAddress.value = true;
    };

    const addEditContact = ref(false);
    const rawContact = ref(null);
    const fetchContact = async () => {
      const ans = await loadContact({ customerUid: props.bareCustomer.ccUid, auditReason: props.auditReason });
      if (!ans) {
        return;
      }
      rawContact.value = ans;
      const keys = Object.keys(ans);
      let tmp = [];
      for (const k of keys) {
        if (k === "patientUid") {
          continue;
        }
        let d = {};
        d.description = k;
        d.selected = ans[k];
        tmp.push(d);
      }
      contact.value = tmp;

      updated.value = updated.value + 1;
    };

    const addContactDetails = async () => {
      addEditContact.value = true;
    };

    const addMoreDocs = ref(false);
    const addPatientDocs = async () => {
      addMoreDocs.value = true;
    };

    const addNewId = ref(false);
    const rawIdDocuments = ref(null);
    const addPatientId = async () => {
      addNewId.value = true;
    };
    const fetchIdScan = async () => {
      const idd = await listIdDocuments({ customerUid: props.bareCustomer.ccUid, auditReason: props.auditReason });
      idDocuments.value = idd;
      rawIdDocuments.value = idd;
      const ans = await loadScannedId({ customerUid: props.bareCustomer.ccUid, auditReason: props.auditReason });
      console.log("ans", ans);
      if (!ans) {
        return;
      }
      const keys = Object.keys(ans);
      let tmp = [];

      for (const k of keys) {
        if (k === "rawData") {
          continue;
        }
        let d = {};
        switch (k) {
          case "first":
            d.description = "First Name";
            break;
          case "last":
            d.description = "Last Name";
            break;
          case "isVeteran":
            d.description = "Veteran?";
            break;
          case "licenseNum":
            d.description = "Number";
            break;
          case "docID":
            d.description = "Document Id";
            break;
          case "idType":
            d.description = "ID Type";
            break;
          case "issuedAt":
            d.description = "Issued At";
            break;
          case "issueDate":
            d.description = "Issue Date";
            break;
          default:
            d.description = k;
        }
        d.selected = ans[k];
        tmp.push(d);
      }
      idScan.value = tmp;
      console.log("idscan is", idScan.value);

      updated.value = updated.value + 1;
    };

    const addEditDocument = ref(false);
    const allRawDocuments = ref(null);
    const addPatientDocument = async () => {
      addEditDocument.value = true;
    };
    const fetchDocumentTypes = async () => {
      const ans = await loadDocumentTypes({ customerUid: props.bareCustomer.ccUid, auditReason: props.auditReason });
      if (ans && ans.length > 0) {
        documentTypes.value = ans;
        allRawDocuments.value = ans;
      } else {
        documentTypes.value = [];
        allRawDocuments.value = [];
      }

      updated.value = updated.value + 1;
    };

    const fetchQualifications = async () => {
      const ans = await loadQualifications({ customerUid: props.bareCustomer.ccUid, auditReason: props.auditReason });
      if (!ans) {
        return;
      }
      const keys = Object.keys(ans);
      let tmp = [];
      for (const k of keys) {
        if (
          k === "fullName" ||
          k === "otherDescription" ||
          k === "patientUid" ||
          k === "cancer" ||
          k === "ptsd" ||
          k === "woman" ||
          k === "dvSurvivor" ||
          k === "medicalRec" ||
          k === "other"
        ) {
          continue;
        }
        let d = {};
        d.selected = ans[k];
        switch (k) {
          case "lowIncome":
            d.description = "Low Income";
            break;
          case "hiv":
            d.description = "HIV/Aids/Cancer Patient";
            break;
          case "terminal":
            d.description = "Terminal Illness";
            break;
          case "bipoc":
            d.description = "BIPOC";
            break;
          case "lgbtq":
            d.description = "LGBTQA+";
            break;
          case "other":
            d.description = "Other";
            d.selected = ans["otherDescription"];
            break;
          case "veteran":
            d.description = "Veteran";
            break;
          case "mobility":
            d.description = "Limited Mobility";
            break;
          case "general":
            d.description = "General";
            break;
          case "refuseDemographics":
            d.description = "Opted Out";
            break;

          default:
            d.description = k;
        }
        tmp.push(d);
      }
      qualifications.value = tmp;
      console.log("qualifications are", qualifications.value);

      updated.value = updated.value + 1;
    };
    const fields = ["description", "selected"];
    const doctypeFields = ["type", "name", "uploadedBy", "uploadedAt"];
    const dispensaryFields = ["pos", "dispensary", "patientName", { key: "externalId", label: "Actions" }];

    const viewDispensaryDocs = async (what) => {
      try {
        setLoading(true);
        lookingUp.value = what.item;
        console.log("what", what);
        const req = {
          mappingUid: what.item.mappedUid,
          integrationUid: what.item.integrationUid,
          partnerUid: what.item.partnerUid,
          patientUid: what.item.patientUid,
          customerId: what.item.externalId
        };
        console.log("fetching saved", req);
        const resp = await fetchSavedPartnerData(req);
        lookupError.value = null;
        showLookupPatients.value = true;
        lookedUpPatients.value = [resp.data];
      } catch (err) {
        lookupError.value = err.message ? err.message : "";
      } finally {
        setLoading(false);
        showLookupPatients.value = true;
      }
    };

    const uploadPatientDocs = ref(false);
    const uploadToPartner = ref(null);
    const uploadDocsTo = async (data) => {
      //TODO: upload docs to dispensary
      console.log("upload docs", data);
      if (!allRawDocuments.value) {
        await fetchDocumentTypes();
      }
      if (allRawDocuments.value && allRawDocuments.value.length > 0) {
        uploadPatientDocs.value = true;
        uploadToPartner.value = data;
      } else {
        root.$bvToast.toast(`No documents to upload.`, {
          title: "Error",
          autoHideDelay: 3000,
          variant: "danger",
          solid: true,
          appendToast: true
        });
      }
    };

    const lookupPatient = async (data) => {
      try {
        setLoading(true);
        lookingUp.value = data.item;
        const req = {
          integrationUid: data.item.integrationUid,
          partnerUid: data.item.partnerUid,
          patientUid: data.item.patientUid
        };
        lookedUpPatients.value = [];
        const resp = await lookupIntegrationPatient(req);
        console.log("Got response", resp);
        lookupError.value = null;
        lookedUpPatients.value = resp.data;
      } catch (err) {
        lookupError.value = err.message ? err.message : "";
      } finally {
        setLoading(false);
        showLookupPatients.value = true;
      }
    };

    const createPatient = async (data) => {
      try {
        setLoading(true);
        const req = {
          patientUid: data.patientUid,
          partnerUid: data.partnerUid,
          integrationUid: data.integrationUid,
          auditReason: props.auditReason
        };
        const res = await createDispensaryPatient(req);
        if (res && res.data) {
          lookedUpPatients.value = [];
          lookedUpPatients.value = [res.data];
        }

        root.$bvToast.toast(`Successfully created patient on dispensary`, {
          title: "Success",
          autoHideDelay: 2000,
          variant: "success",
          solid: true,
          appendToast: true
        });
        await fetchInDispensaries();
      } catch (err) {
        let msg = err.message;
        console.log(err, err.response);
        if (err.response && err.response.data && err.response.data.message) {
          msg = err.response.data.message;
        }
        root.$bvToast.toast(`Error creating patient on dispensary:  ${msg}`, {
          title: "Error",
          autoHideDelay: 14000,
          variant: "danger",
          solid: true,
          appendToast: true
        });
      } finally {
        setLoading(false);
      }
    };
    const docHtml = (doc) => {
      let ret = `${doc.name} <br/>uploaded: ${doc.uploadedBy} `;
      if (doc.expiration) {
        ret += `<br/>Exp: ${doc.expiration}`;
      }
      return ret;
    };

    onMounted(() => {
      console.log("mounted!", props.bareCustomer);
      if (props.bareCustomer && !props.isOperator) {
        showResponse.value = false;
        console.log(props.bareCustomer.validated);
        patientValidated.value = props.bareCustomer.validated || !props.bareCustomer.ccUid;
        if (patientValidated.value) {
          showResponse.value = true;
          apiResponse.value = "Patient cannot be validated: Already validated or missing patient information.";
          apiResponseType.value = "warning";
        }
      }
      if (props.bareCustomer.hasContacts) {
        fetchContact();
      }
    });

    return {
      customer,
      getLoading,
      lookupError,
      showLookupPatients,
      lookedUpPatients,
      onClose,
      props,
      fetchQualifications,
      fetchDocumentTypes,
      fetchContact,
      fetchAddress,
      fetchReleases,
      fetchIdScan,
      getDocument,
      qualifications,
      documentTypes,
      doctypeFields,
      dispensaryFields,
      idDocuments,
      idScan,
      fields,
      releases,
      address,
      contact,
      updated,
      largeImage,
      largeImageView,
      setLargeImage,
      largeImageDoc,
      showResponse,
      apiResponse,
      apiResponseType,
      validatePatient,
      patientValidated,
      confirmValidatePatient,
      showConfirmation,
      cancelView,
      validationProgress,
      fetchInDispensaries,
      dispensaries,
      uploadDocsTo,
      lookupPatient,
      lookingUp,
      createPatient,
      viewDispensaryDocs,
      cancelPatientMap,
      addContactDetails,
      addEditContact,
      rawContact,
      addContactAddress,
      addEditAddress,
      rawAddress,
      addPatientId,
      addNewId,
      rawIdDocuments,
      addEditDocument,
      allRawDocuments,
      addPatientDocument,
      disableDocument,
      uploadPatientDocs,
      uploadToPartner,
      addMoreDocs,
      addPatientDocs,
      docHtml,
      tabIndex
    };
  }
};
</script>

<style scoped>
.badge-margin {
  margin-right: 5px;
}
</style>
